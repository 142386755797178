import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaTwitterSquare } from "react-icons/fa";
import {FaGithubSquare } from "react-icons/fa";

const Social = () => {
  return (
    <div className='home-social'>
        <a href="https://www.linkedin.com/in/eng-nsiimire-annet-3b903439" className='home-social-icon' target="_blank">
<FaLinkedin></FaLinkedin>
        </a>

        {/* <a href="https://github.com/benard-awuko" className='home-social-icon' target="_blank">
<FaGithubSquare></FaGithubSquare>
        </a> */}

        <a href="https://twitter.com/NsiimireAnnet" className='home-social-icon' target="_blank">
<FaTwitterSquare></FaTwitterSquare>
        </a>

    </div>
  )
}

export default Social