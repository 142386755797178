import { type } from '@testing-library/user-event/dist/type';
import React, {useEffect, useState} from 'react';
import {BsColumnsGap} from 'react-icons/bs';
import {FaReact, FaRegThumbsUp, FaArrowRight, FaTimes} from 'react-icons/fa';
import {HiOutlineCode, HiOutlineCog} from 'react-icons/hi';


const Services = () => {
                      


useEffect(()=>{
document.title = 'Gallery | Eng. Nsiimire Annet'
},[]);


  return (
    <section className='section-services'>
      <h2 className='section-title'>Gallery</h2>
      {/* <span className='section-subtitle section-subtitle1'>Gallery</span> */}
      <div className='services-container services-container1'>
              <img src='./images/2.jpg' className='gallery-image'></img>             
              <p className='contact-p contact-p-a'>A courtesy call to the Society of Engineers United Arab Emirates in December 2023</p>
      </div>



    </section>
  )
}

export default Services