import React, {useEffect} from 'react';
import Qualifications from '../components/Qualifications';
import { FaPlug, FaBook, FaBookOpen, FaChartPie , FaPowerOff,FaUserFriends, FaUsers} from 'react-icons/fa'

const Skills = () => {

useEffect(()=>{
document.title = 'Knowledge & Skills | Eng. Nsiimire Annet'
},[]);


  return (
    <>
    <section className='skills-section'>
      <h2 className='section-title'>My Knowledge & Skills</h2>
      <span className='section-subtitle section-subtitle1'>Skills</span>
      <div className='skills-container'>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaChartPie></FaChartPie>
                    </div>
               <h3 className='skill-h3'>Project Management</h3>
                  

          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaBook></FaBook>
                    </div>
               <h3 className='skill-h3'>Learning</h3>
          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaBookOpen></FaBookOpen>
                    </div>
               <h3 className='skill-h3'>Literacy</h3>
          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaUsers></FaUsers>
                    </div>
               <h3 className='skill-h3'>Life & Social Skills</h3>
          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaPlug></FaPlug>
                    </div>
               <h3 className='skill-h3'>Electrical Engineering</h3>
          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaPowerOff></FaPowerOff>
                    </div>
               <h3 className='skill-h3'>Power Systems</h3>
          </article>
          <article className='skill-category'>
                    <div className='skill-top-icon'>
                      <FaUserFriends></FaUserFriends>
                    </div>
               <h3 className='skill-h3'>Humanitarian Engineering </h3>
          </article>
          {/* <article className='skill-category'>
            <div className='skill-top-icon'>
                      <FaGithub></FaGithub>
                    </div>
               <h3 className='skill-h3'>Git & Github Skills</h3>
               <div className='all-skills'>

                <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Git installation</p>
                     </div>
                     
                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Branching</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Merging</p>
                     </div>
                     
                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Working with remote repositories</p>
                     </div>


                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Fetching</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Pulling</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Collaboration</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Diffing</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Stashing</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Undoing Changes</p>
                     </div>

                  </div>

          </article> */}
          {/* <article className='skill-category'>
            <div className='skill-top-icon'>
                      <FaUserTie></FaUserTie>
                    </div>
             <h3 className='skill-h3'>Professional Skills</h3>
             <div className='all-skills'>
                     
                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Problem-Solving Skills</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Interpersonal Skills</p>
                     </div>

                      <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Communication Skills</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Leadership Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Time Management Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Team Building Skills.</p>
                     </div>

                     <div className='single-skill'>
                     <div className='skill-icon'>
                       <FaRegCheckCircle></FaRegCheckCircle>
                     </div>
                     <p className='skill-name'>Project Management Skills.</p>
                     </div>

                      

                    

                  </div>

          </article> */}
        
        
      </div>
    </section>
    <Qualifications></Qualifications>
    </>
  )
}

export default Skills