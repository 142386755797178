import React from 'react'
import {FaCog} from "react-icons/fa";
import {FaPaperPlane } from "react-icons/fa";
import  {Link} from 'react-router-dom'
const Data = () => {
  return (
    <div className='home-data'>
        <h1 className='home-title'><span className='home-smile'><FaCog></FaCog></span><p>Eng. Nsiimire Annet</p></h1>
        <div className='title-cover'>
       <div className='my-line'></div> <h3 className='home-subtitle'>Independent  Consultant</h3><div className='my-line '></div>
        </div>
        <p className='home-description'>
Independent Consultant with over 20 years’ progressive experience within the private and public sector. I am passionate about facilitating knowledge transfer and skills development. My areas of focus are Project Management, Innovation and Business Management Support services. I am a Trainer: Currently, I train Humanitarian Skills for Engineers in partnership with CEDAT - Makerere University, Uganda Institution of Professional Engineers (UIPE) and RedR UK. 
         </p>
       {/* <p className='home-description'>
Career Guidance Program - Technical Lead at UIPE and 8M CD Skills Center. I serve as a Council Member Uganda Institution of Professional Engineers (UIPE) and on several council committees. I am an Executive Member: UIPE Kampala Branch.
        </p>
        <p className='home-description'>
A Member of Energy Working Group: Federation of African Engineering Organizations (FAEO) and Engineering Think Tank: under Science Technology and Innovation (STI) Secretariat.
I have mentored a number of professionals and participated in learners’ career guidance to improve career preparation and management.

        </p> */}
         <Link to="contact" className='button button--flex hello-btn'>
         Say Hello<span className='hello-icon'><FaPaperPlane></FaPaperPlane></span>
         </Link>
    
    </div>
  )
}

export default Data