import React, { useRef, useEffect, useState } from 'react';
import emailjs from '@emailjs/browser';
import {FaMobileAlt, FaWhatsapp, FaRegEnvelope} from 'react-icons/fa';
import {GoLocation} from 'react-icons/go'
// import ContactCarousel from '../components/ContactCarousel';
const Contact = () => {
       let [successMessage, setSuccessMessage] = useState(false);
        let [errorMessage, setErrorMessage] = useState(false);
        let [sendingMessage, setSendingMessage] = useState(false);
        let formInput1  = useRef(null);
        let formInput2  = useRef(null);
        let formInput3  = useRef(null);
        let formInput4  = useRef(null);
        let formInput5  = useRef(null);

let sendFunc =()=>{
  setSendingMessage(true);
    setSuccessMessage(false);
    setErrorMessage(false);
  
}




  useEffect(()=>{
document.title = 'Contact | Eng. Nsiimire Annet';

},[]);

    const form = useRef();

  const sendEmail = (e) => {
   
    e.preventDefault();

    emailjs.sendForm('service_op4c6pnBen', 'template_jmd63q9', form.current, 'wXbRm_KBuWgQ4kVbe')
      .then((result) => {
          // console.log(result.text);
         if(result){
setSuccessMessage(true);
          setSendingMessage(false);

              setTimeout(() => {
            setSuccessMessage(false);
        }, 3000);

     formInput1.current.value = '';
    formInput2.current.value = '';
    formInput3.current.value = '';
    formInput4.current.value = '';
    formInput5.current.value = '';

         }
          
    
   
      }, (error) => {
          // console.log(error.text);

          if (error){
setErrorMessage(true);
          setSendingMessage(false);
           setTimeout(() => {
           setErrorMessage(false);
        }, 3000);

          }

     formInput1.current.value = '';
    formInput2.current.value = '';
    formInput3.current.value = '';
    formInput4.current.value = '';
    formInput5.current.value = '';
          
      });
  };
  return (
    <section className='section-cover contact-cover' >
   <h2 className='section-title'>Contact Me</h2>
      <span className='section-subtitle section-subtitle1'>Get In Touch</span>
         <div className='content-container contact-section'>

<div className='form-cover'>
  <form ref={form} onSubmit={sendEmail}>
      <label >Name</label>
      <input type="text" name="user_name" ref={formInput1}></input>
      <label>Email</label>
      <input type="email" name="user_email" ref={formInput2}></input>
      <label>Contact Number</label>
      <input type="number" name="user_contact" ref={formInput3}></input>
      <label>Subject</label>
      <input type="text" name="user_subject" ref={formInput4}></input>


      <label>Message</label>
      <textarea name="message"  ref={formInput5}/>
       <div  className={`${sendingMessage?'success-box':'hide'}`}><p>Sending...</p></div>
      <div  className={`${successMessage?'success-box':'hide'}`}><p>Thank you! Your message has been sent.</p></div>
      <div className={`${errorMessage?'error-box':'hide'}`}><p>Oh, sorry! An error occurred.</p></div>
      <input type="submit" value="Send Message" onClick={sendFunc} />
    </form>
</div>
           <div className='contact-slides contact-cards'> 
       
          
          
          

          <article className='single-service'>
            <div className='service-icon'>
                    <FaMobileAlt></FaMobileAlt>
              </div>
            <p className='contact-p'>+256772 408878 </p>
            
          
          </article>

          {/* <article className='single-service'>
            <div className='service-icon'>
                    <FaWhatsapp></FaWhatsapp>
              </div>
            <p className='contact-p'>+256715 871585 </p>
            
          
          </article> */}

           <article className='single-service'>
            <div className='service-icon'>
                    <FaRegEnvelope></FaRegEnvelope>
              </div>
            <p className='contact-p'>info@nsiimireannet.com </p>
            
          
          </article>

          <article className='single-service'>
            <div className='service-icon'>
                    <GoLocation></GoLocation>
              </div>
            <p className='contact-p'>Kampala, Uganda</p>
            
          
          </article>



     
</div>

         </div>

    </section>
  )
}

export default Contact