import React from 'react';
import Social from './Social';
import Data from './Data';
import ScrollDown from './ScrollDown';
import Testimonials from '../components/Testimonials'

const HomeComponent = () => {
  return (
    <>
    <section className='home section home-section'>
       <div className='home-container container grid'>
           <div className='home-content grid'>
                      <Social></Social>
                      <div className='home-img'></div>
                     <Data></Data>
           </div>
           <ScrollDown></ScrollDown>

       </div>
    </section>
    {/* <Testimonials></Testimonials> */}
   </>
  )
}

export default HomeComponent;