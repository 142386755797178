import React, {useEffect} from 'react'
import Info from '../components/Info';
import {Link} from 'react-router-dom';
import { FaHandshake, FaLightbulb, FaHeart, FaSearchPlus, FaCheck} from 'react-icons/fa'

const About = () => {
useEffect(()=>{
document.title = 'About | Eng. Nsiimire Annet'
},[])



  return (<section className='about section section-about'>
    <h2 className='section-title'>About Me</h2>
    {/* <span className='section-subtitle section-subtitle1'>My Attributes</span> */}
   <p className='contact-p contact-p-b'>Career Guidance Program - Technical Lead at UIPE and 8M CD Skills Center.
I serve as a Council Member Uganda Institution of Professional Engineers (UIPE) and on several council committees. I am an Executive Member: UIPE Kampala Branch.
<br></br>A Member of Energy Working Group: Federation of African Engineering Organizations (FAEO) and Engineering Think Tank: under Science Technology and Innovation (STI) Secretariat.
I have mentored a number of professionals and participated in learners’ career guidance to improve career preparation and management.</p>
    <div className='about-container '>
      <div className='about-img-container'>
      <img src='./images/1.jpg' alt='Eng. Nsiimire Annet' className='about-img'></img>
   </div>
       <div className='about-data'>
       <div className='about-info '>
        <div className='about-box'>
           <div className='about-icon-cover'>
          <span className='about-icon'><FaLightbulb></FaLightbulb>  </span>
          </div>
          <h3 className='about-title'>Career Enthusiast</h3>
          {/* <p>I develop innovative approaches and ideas. More so, I act with imagination and display original thinking and creativity.</p> */}
        </div>
        <div className='about-box'>
            <div className='about-icon-cover'>
          <span className='about-icon'><FaHeart></FaHeart></span>
          </div>
          <h3 className='about-title'>Available</h3>
          {/* <p>I’m awash with the zeal that brings life to work. I’m ever optimistic, I love what I do, I’m self-driven and willing to endure hardship for the sake of what I care about.</p> */}
        </div>
        <div className='about-box'>
          <div className='about-icon-cover'>
          <span className='about-icon'><FaSearchPlus></FaSearchPlus></span>
          </div>
          <h3 className='about-title'>Accessible</h3>
          {/* <p>I focus on all areas of a project or task, no matter the size. More so, I’m thorough in reviewing my work. And so, my works are generally meticulous.</p> */}
        </div>
        <div className='about-box'>
          <div className='about-icon-cover'>
          <span className='about-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='about-title'>Committed</h3>
          {/* <p>I’m intrinsically motivated to do what is right. I make ethical decisions and address challenges calmly and purposefully. </p> */}
        </div>
        <div className='about-box'>
          <div className='about-icon-cover'>
          <span className='about-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='about-title'>Independent Leader</h3>
          {/* <p>I’m intrinsically motivated to do what is right. I make ethical decisions and address challenges calmly and purposefully. </p> */}
        </div>
    
    </div>
        {/* <Link to="cv" className='button button--flex hello-btn'>
         My Resume<span className='hello-icon'><FaBookReader></FaBookReader></span>
         </Link> */}


        </div>


    </div>



  </section>

  )
  
}

export default About