import React, {useEffect, useRef, useState} from 'react'
import {NavLink} from 'react-router-dom';
import {FaBars} from 'react-icons/fa';
import {FaTimes} from 'react-icons/fa';

const Nav = () => {
    let [value1, setValue1] = useState(true);
     let [value2, setValue2] = useState(false); 
     let [fixedState, setFixedState] = useState(false);

   
 let navLinks = useRef(null);
 let navContainer = useRef(null)

 let toggleFunction1 =()=>{
     
     let navLinksContainer = navContainer.current;
   let navLinksHeight =  navLinks.current.getBoundingClientRect().height;
        navLinksContainer.style.height = `${navLinksHeight}px`;
        setValue1(false);
        setValue2(true)
        
 }

  let toggleFunction2 =()=>{
      let navLinksContainer = navContainer.current;
     navLinksContainer.style.height = 0;
        setValue2(false);
        setValue1(true)
      
 }

 let hideContainer =(e)=>{
     if (e.target.classList.contains('nav-item')){
           let navLinksContainer = navContainer.current;
           navLinksContainer.style.height = 0;
           setValue2(false);
           setValue1(true)
     }

 }

 window.addEventListener('scroll',()=>{
           if(window.scrollY > 300){
               setFixedState(true);
           }
           else{
               setFixedState(false);
           }



 })

 
  return (
    <nav className={`${fixedState? 'nav fixed-nav': 'nav'}`} onClick={hideContainer} >
        <div className='nav-center'>
                
               <div className='nav-header'>
               <NavLink to='/'>     <h2 className='nav-logo'>Eng. Nsiimire <span className='lsw'>Annet</span></h2></NavLink>
               <div className={` ${value1? 'nav-toggle' : ' hide'}`} onClick={toggleFunction1}>
               <FaBars></FaBars>
               </div>

               <div className={` ${value2? 'nav-close' : ' hide'}`} onClick={toggleFunction2}>
               <FaTimes></FaTimes>
               </div>
                 
               </div>

                <div className='nav-container' ref={navContainer}>
               <ul className='nav-links' ref={navLinks}>
                    <li className='nav-link' >
                      <NavLink to='/'  className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')} end>  Home</NavLink>
                    </li>
                    <li className='nav-link'>
                         <NavLink to='about' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}>  About</NavLink>
                    </li>

                    <li className='nav-link'>
                         <NavLink to='knowledge-skills' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}> Knowledge & Skills</NavLink>
                    </li>

                     <li className='nav-link'>
                         <NavLink to='gallery' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}> Gallery</NavLink>
                    </li>

                    {/* <li className='nav-link'>
                         <NavLink to='portfolio' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}> Portfolio</NavLink>
                    </li> */}

                    <li className='nav-link'>
                         <NavLink to='contact' className={({ isActive }) => (isActive ? 'nav-item active' : 'nav-item')}>Contact</NavLink>
                    </li>


               </ul>
               </div>


        </div>
    </nav>
  )
  
}

export default Nav