import React,{useState} from 'react';
import {FaGraduationCap, FaBriefcase,FaCalendarAlt} from 'react-icons/fa';

const Qualifications = () => {
            let [educState, setEducState] = useState(true);
            let [expState, setExpState] = useState(false);


let eduFunc =()=>{
setExpState(false);
setEducState(true)

}

let expFunc =()=>{
setExpState(true);
setEducState(false);
}


  return (
    <section className='qualifications-section'>
        <h2 className='section-title'>Qualifications</h2>
        <span className='section-subtitle qualifications-subtitle'>My Journey</span>
        <div className='qualification-container container'>
            <div className='qualifications-tabs'>
                  <div className={`${educState? 'qualification-btn btn--flex qualification-active': 'qualification-btn btn--flex'}`} onClick={eduFunc}>
                             <FaGraduationCap></FaGraduationCap>  
                              <p>Education</p>
                  </div>

                  <div className={`${expState? 'qualification-btn btn--flex qualification-active': 'qualification-btn btn--flex'}`} onClick={expFunc}>
                          <FaBriefcase></FaBriefcase>   
                             <p> Experience</p>
                  </div>
            </div>

            <div className='qualification-sections'>
                <div className={`${educState?'qualification-content qualification-content-active': 'qualification-content'}`}  >
                    <div className='qualification-data'>
                        <div  >
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Masters Degree, Project Planning and Management</h3>
                            <span className='qualification-subtitle'>Uganda Management InstituteUganda Management Institute</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2011 - 2012</p>
                            </div>
                        </div>
                        
                       
                     
                    </div>
                    
                    <div className='qualification-data'>
                        
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>


                        <div>
                            <h3 className='qualification-title'>Postgraduate Diploma, Project Management</h3>
                            <span className='qualification-subtitle'>Uganda Management Institute</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2010 - 2011</p>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        
                        <div>
                            <h3 className='qualification-title'>Bachelor’s Degree, Telecommunications Engineering</h3>
                            <span className='qualification-subtitle'>Kyambogo University</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2006 - 2008</p>
                            </div>
                        </div>
                        
                    </div>
                    <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        
                        <div>
                            <h3 className='qualification-title'>Higher National Diploma, Electrical and Electronics Engineering</h3>
                            <span className='qualification-subtitle'>Uganda Polytechnic Kyambogo</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>1999 - 2001</p>
                            </div>
                        </div>
                        
                    </div>
                     <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        
                        <div>
                            <h3 className='qualification-title'>Diploma, Electrical and Electronics Engineering</h3>
                            <span className='qualification-subtitle'>Uganda Technical College Bushenyi</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>1994 - 1996</p>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className={`${expState?'qualification-content exp qualification-content-active': 'exp qualification-content'}`} >
                    <div className='qualification-data'>
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Self-employed</h3>
                            <span className='qualification-subtitle'>Independent Consultant</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>May 2022 - Present</p>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className='qualification-data'>
                         
                        <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>


                        <div>
                            <h3 className='qualification-title'>Umeme Ltd</h3>
                            <span className='qualification-subtitle'>Telecommunications Engineer</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2010 - 2012</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Umeme Ltd</h3>
                            <span className='qualification-subtitle'>Protection Technician</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2005 - 2010</p>
                            </div>
                        </div>
                       
                    </div>
                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Pazel Conroy Consulting Limited</h3>
                            <span className='qualification-subtitle'>Project Execution Specialist</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2020 - 2021</p>
                            </div>
                        </div>
                       
                    </div>
                     <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Pazel Conroy Consulting Limited</h3>
                            <span className='qualification-subtitle'>
Water and Sanitation Specialist</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2019 - 2019</p>
                            </div>
                        </div>
                       
                    </div>
                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Radix Management Consulting (U) Limited</h3>
                            <span className='qualification-subtitle'>
Project Execution Specialist</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2018 - 2018</p>
                            </div>
                        </div>
                       
                    </div>
                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Uganda Technology And Management University</h3>
                            <span className='qualification-subtitle'>
Part-time Lecturer</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2014 - 2018</p>
                            </div>
                        </div>
                       
                    </div>
                    <div className='qualification-data'>
                         <div>
                            <span className='qualification-rounder'></span>
                            <span className='qualification-line'></span>
                        </div>
                        <div>
                            <h3 className='qualification-title'>Uganda Electricity Distribution Company Limited</h3>
                            <span className='qualification-subtitle'>
Installation Inspector</span>
                            <div className='qualification-calendar'>
                                <FaCalendarAlt></FaCalendarAlt><p>2001 - 2005</p>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </div>
        </div>
  
        </section>
  )
}

export default Qualifications